import React, { useState, useEffect } from "react";
import { useTheme } from "../../../utils/ThemeProvider";
import { MBColor } from "../../../const/Colors";
import {imageShadowUrls, imageUrls, preloadImage} from "./Images";
import Folders from "./Folder";
import "./MB.css";
import { isMobileDeviceV3 } from "../../../utils/StyleUtils";
import { MobileWidth } from "../../../const/Style";
import { Star } from "../../../const/Interfaces";
import useSound from "use-sound";
// @ts-ignore
import page from "../../../static/mb/page.wav";
import { Link } from "react-router-dom";

const GameMB: React.FC = () => {
    const [startGame, setStartGame] = useState<boolean>(false);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const savedFoundImages = JSON.parse(localStorage.getItem("foundImages") || "[]");
    const [foundImages, setFoundImages] = useState<string[]>(savedFoundImages);
    const [playButton] = useSound(page, { volume: 1 });
    const [oxygen, setOxygen] = useState(100);
    const { setBackground } = useTheme();
    const [stars, setStars] = useState<null | Star[]>(null);

    // Modal states
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImages] = useState([imageShadowUrls[0], imageShadowUrls[1]]); // Example images
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const addImage = (img: string) => {
        playButton();
        const prevArray = Array.from(foundImages);
        const prev = new Set<string>(prevArray).add(img);
        const newImages = Array.from(prev);
        setFoundImages(newImages);
        localStorage.setItem("foundImages", JSON.stringify(newImages));
    };

    useEffect(() => {
        const isMobile: boolean = isMobileDeviceV3(MobileWidth);
        const rStars = isMobile ? 0.2 : 1;
        setStars(
            Array.from({ length: 1000 }, (_, index) => ({
                id: index,
                x: Math.random() * 100,
                y: Math.random() * 100,
                wh: Math.random() + rStars,
            }))
        );
        setBackground(MBColor);
        Promise.all(imageUrls.map(preloadImage))
            .then(() => setImagesLoaded(true))
            .catch((error) => {
                console.error("Failed to preload images:", error);
                setImagesLoaded(true);
            });
    }, [setBackground]);

    useEffect(() => {
        const interval = setInterval(() => {
            setOxygen((prevOxygen) => Math.max(prevOxygen - 1, 0));
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const handleStartGame = () => {
        setStartGame(true);
        setOxygen(100);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setCurrentImageIndex(0);
    };

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % modalImages.length);
    };

    const handlePreviousImage = () => {
        setCurrentImageIndex((prevIndex) =>
            (prevIndex - 1 + modalImages.length) % modalImages.length
        );
    };

    if (!startGame) {
        return (
            <div className={"mb"}>
                <div className={"fit"}>
                    <div className={"text main-menu-buttons menu-buttons"} onClick={handleStartGame}>
                        начать
                    </div>
                </div>
            </div>
        );
    }

    if (oxygen === 0) {
        alert("кислород закончился!");
        localStorage.setItem("foundImages", JSON.stringify(foundImages));
        setStartGame(false);
        return (
            <div className={"mb"}>
                <div className={"fit"}>
                    <div>конец игры</div>
                    <div onClick={handleStartGame}>снова</div>
                </div>
            </div>
        );
    }

    if (!imagesLoaded || !stars) {
        return (
            <div className={"mb"}>
                <div className={"fit"}>
                    <div className={"text"}>загрузка . . .</div>
                </div>
            </div>
        );
    }

    return (
        <div className={"mb"}>
            <div className={"fit"}>
                <Link to={"/mb"}>
                    <div className={"text menu-buttons back"}>назад</div>
                </Link>
                <div className="game">
                    <div className={"text counter"}>{foundImages.length || 0}/15</div>
                    <div className={"text oxygen-info"}>{`${oxygen}% кислород`}</div>
                    <div className="oxygen-bar">
                        <div style={{ height: `${oxygen}%` }}></div>
                    </div>
                    <div id="star-sky" className="star-sky">
                        {stars.map((star) => (
                            <div
                                key={star.id}
                                className="star"
                                style={{
                                    left: `${star.x}%`,
                                    top: `${star.y}%`,
                                    width: star.wh,
                                    height: star.wh,
                                }}
                            />
                        ))}
                    </div>
                    <div className="computer">
                        <Folders foundImages={foundImages} addImage={addImage} playButton={playButton} />
                    </div>
                    {/* "?" Button */}
                    <div
                        className="text menu-buttons helper"
                        onClick={() => setIsModalOpen(true)}
                        style={{ position: "absolute", bottom: "20px", right: "20px", cursor: "pointer" }}
                    >
                        ?
                    </div>
                </div>
            </div>

            {/* Modal */}
            {isModalOpen && (
                <div className="modal">
                    <span className="close" onClick={handleModalClose}>&times;</span>
                    <img className="modal-content" src={modalImages[currentImageIndex]} alt="Selected"/>
                    <button className="next-button" onClick={handleNextImage}>
                        &#8250;
                    </button>
                    <button className="prev-button" onClick={handlePreviousImage}>
                                  &#8249;
                    </button>
                </div>
            )}
        </div>
    );
};

export default GameMB;
